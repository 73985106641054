import { Entry, isEntry } from '@/lib/parsers/entries';
import { PageEntry } from '@/lib/parsers/entries/customEntryTypes';
import { isObjectEmpty } from '@/lib/utils/isObjectEmpty';
import Image from '../Image';
import Box from '../ui/Box';
import * as styles from './FeaturedCard.css';
import FeaturedDefaultCard from './FeaturedCard.Default';
import FeaturedEventCard from './FeaturedCard.Event';

const FeaturedCard = (props: Entry) => {
  const { entryImage } = props as PageEntry;

  const isEvent = isEntry(props, 'event');

  if (isObjectEmpty(props)) return null;

  return (
    <Box cx={{ position: 'relative' }}>
      <Box className={styles.imageWrapper}>
        <Image className={styles.image} alt="featured-image" fixedRatio {...entryImage} />
      </Box>
      {isEvent ? <FeaturedEventCard {...props} /> : <FeaturedDefaultCard {...props} />}
    </Box>
  );
};

export default FeaturedCard;
