import BlockHeader from '@/components/BlockHeader';
import DownloadButton from '@/components/DownloadButton';
import Grid from '@/components/ui/Grid';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../../BlockContainer';

export const FileDownloadBlock = createBlock<'blocks_fileDownload_BlockType'>(
  ({ heading, fileMultiple, _blockMeta: meta }) => {
    return (
      <BlockContainer
        {...{
          colorSet: 'white',
          paper: true,
          meta,
          marginY: true,
          disableTransition: true,
        }}>
        <BlockHeader heading={heading} HeadingProps={{ cx: { mB: 'xl' }, autoMargin: false }} />
        <Grid rowGutter>
          {/*
          // FIXME: Temporary hack
           */}
          {fileMultiple?.map((file: any) => {
            return (
              <Grid.Col key={file.id} sm={5}>
                <DownloadButton document={file} />
              </Grid.Col>
            );
          })}
        </Grid>
      </BlockContainer>
    );
  }
);

export default FileDownloadBlock;
