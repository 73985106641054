import Btn, { BtnProps } from '../ui/Btn';
import Container, { ContainerProps } from '../ui/Container';
import * as styles from './BlockContainer.css';

export type BlockContainerBtnProps = ContainerProps<
  'div',
  {
    BtnProps?: BtnProps;
    isActive?: boolean;
  }
> &
  React.PropsWithChildren;

export const BlockContainerBtn = ({
  BtnProps,
  children,
  isActive,
  ...props
}: BlockContainerBtnProps) => {
  if (!isActive) return null;

  return (
    <Container {...props}>
      <Btn
        className={styles.button}
        fullWidth
        variant="text"
        color="primary"
        underline="always"
        {...BtnProps}>
        {children || 'Expand me'}
      </Btn>
    </Container>
  );
};
