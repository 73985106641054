/* eslint-disable @typescript-eslint/no-var-requires */
import { Block, filterBlocks } from '@/lib/parsers/blocks';
import { assertUnreachable } from '@liquorice/utils';

import { useViewBlocks } from '@/lib/store/hooks';

import AccordionBlock from './BlockComponents/AccordionBlock';
import CategoryTypeBlock from './BlockComponents/CategoryTypeBlock';
import DividerBlock from './BlockComponents/DividerBlock';
import { EntryCardsBlock } from './BlockComponents/EntryCardsBlock';
import EventCreditsBlock from './BlockComponents/EventCreditsBlock';
import EventIndexBlock from './BlockComponents/EventIndexBlock';
import EventSearchBlock from './BlockComponents/EventSearchBlock';
import ExpandingCardBlock from './BlockComponents/ExpandingCardBlock';
import ExpandingCardsBlock from './BlockComponents/ExpandingCardsBlock';
import { ExternalCardBlockOverview } from './BlockComponents/ExternalCardBlock';
import FeaturedEventBlock from './BlockComponents/FeaturedEventBlock';
import FileDownloadBlock from './BlockComponents/FileDownloadBlock';
import FormBlock from './BlockComponents/FormBlock';
import ImageAndTextBlock from './BlockComponents/ImageAndTextBlock';
import ImageBlock from './BlockComponents/ImageBlock';
import ImageCarouselBlock from './BlockComponents/ImageCarouselBlock';
import LogoAndTextBlock from './BlockComponents/LogoAndTextBlock';
import OverviewCardBlock from './BlockComponents/OverviewCardBlock';
import OverviewCardsBlock from './BlockComponents/OverviewCardsBlock';
import QuoteBlock from './BlockComponents/QuoteBlock';
import QuoteCarouselBlock from './BlockComponents/QuoteCarouselBlock';
import RichTextBlock from './BlockComponents/RichTextBlock';
import SimpleCardBlock from './BlockComponents/SimpleCardBlock';
import VideoBlock from './BlockComponents/VideoBlock';
import BlocksContextProvider, { BlocksContextProviderProps } from './Blocks.Provider';
import { BlockProvider } from './useBlock';

export const BlockInner = (block: Block) => {
  switch (block.__typename) {
    // Don't render non-top level blocks
    case 'blocks_accordionItem_BlockType':
      return null;
    case 'blocks_eventCredit_BlockType':
      return null;
    case 'blocks_richText_BlockType':
      return <RichTextBlock {...block} />;
    case 'blocks_accordion_BlockType':
      return <AccordionBlock {...block} />;
    case 'blocks_simpleCard_BlockType':
      return <SimpleCardBlock {...block} />;
    case 'blocks_expandingCard_BlockType':
      return <ExpandingCardBlock {...block} />;
    case 'blocks_externalCard_BlockType':
      return <ExternalCardBlockOverview {...block} />;
    case 'blocks_overviewCard_BlockType':
      return <OverviewCardBlock {...block} />;
    case 'blocks_expandingCards_BlockType':
      return <ExpandingCardsBlock {...block} />;
    case 'blocks_overviewCards_BlockType':
      return <OverviewCardsBlock {...block} />;
    case 'blocks_entryCards_BlockType':
      return <EntryCardsBlock {...block} />;
    case 'blocks_eventIndex_BlockType':
      return <EventIndexBlock {...block} />;
    case 'blocks_categoryType_BlockType':
      return <CategoryTypeBlock {...block} />;
    case 'blocks_eventSearch_BlockType':
      return <EventSearchBlock {...block} />;
    case 'blocks_eventCredits_BlockType':
      return <EventCreditsBlock {...block} />;
    case 'blocks_featuredEvent_BlockType':
      return <FeaturedEventBlock {...block} />;
    case 'blocks_imageAndText_BlockType':
      return <ImageAndTextBlock {...block} />;
    case 'blocks_logoAndText_BlockType':
      return <LogoAndTextBlock {...block} />;
    case 'blocks_imageCarousel_BlockType':
      return <ImageCarouselBlock {...block} />;
    case 'blocks_image_BlockType':
      return <ImageBlock {...block} />;
    case 'blocks_video_BlockType':
      return <VideoBlock {...block} />;
    case 'blocks_quote_BlockType':
      return <QuoteBlock {...block} />;
    case 'blocks_quoteCarousel_BlockType':
      return <QuoteCarouselBlock {...block} />;
    case 'blocks_fileDownload_BlockType':
      return <FileDownloadBlock {...block} />;
    case 'blocks_divider_BlockType':
      return <DividerBlock {...block} />;
    case 'blocks_form_BlockType':
      return <FormBlock {...block} />;
    default:
      return assertUnreachable(block);
  }
};

type BlocksProps = {
  blocks?: Block[];
} & BlocksContextProviderProps;

const Blocks = ({ blocks: maybeBlocks, ...context }: BlocksProps) => {
  const viewBlocks = useViewBlocks();

  const blocks = maybeBlocks ? filterBlocks(maybeBlocks).blocks : viewBlocks.blocks;

  const blocksOutput = blocks.map((v, i) => (
    <BlockProvider key={i + 1} value={v}>
      <BlockInner {...v.block} />
    </BlockProvider>
  ));

  return blocks.length ? (
    <BlocksContextProvider {...context}>{blocksOutput}</BlocksContextProvider>
  ) : (
    <></>
  );
};

export const BlockChildren = Blocks;

export default Blocks;
