import UnstyledAccordion from '@/components/UnstyledAccordion';
import Txt from '@/components/ui/Txt';
import { createBlock } from '@/lib/parsers/blocks';
import { vars } from '@/theme/vars.css';
import { BlockContainer } from '../..';

export const AccordionBlock = createBlock<'blocks_accordion_BlockType'>(
  ({ _blockMeta: meta, children }) => {
    return (
      <BlockContainer
        {...{
          meta,
          marginY: true,
          disableTransition: true,
        }}>
        <UnstyledAccordion
          items={
            // FIXME: Temporary hack
            children?.map((child: any, index: number) => {
              return {
                index,
                style: { borderColor: vars.palette.colors.primaryTintLight },
                HandleProps: { cx: { pY: 'sm' } },
                LabelProps: {
                  children: child?.heading,
                  underline: 'none',
                  weight: 'bold',
                  color: 'primary',
                  variant: 'text',
                },
                CaretProps: {
                  index,
                  openIconProps: {},
                },
                BodyProps: {
                  index,
                  children: <Txt html>{child?.content}</Txt>,
                },
              };
            }) ?? []
          }
        />
      </BlockContainer>
    );
  }
);

export default AccordionBlock;
