import { EntryCardDefault } from '@/components/EntryCard';
import { createBlock } from '@/lib/parsers/blocks';
import { useSimpleCard } from './useSimpleCard';

export const SimpleCardBlock = createBlock<'blocks_simpleCard_BlockType'>(({ ...props }) => {
  const item = useSimpleCard(props);

  if (!item) return null;

  return (
    <EntryCardDefault
      {...{
        disableLinkWrap: true,
        item,
        ExtraProps: {
          customText: props.linkField?.children,
        },
      }}
    />
  );
});

export default SimpleCardBlock;
