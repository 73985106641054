'use client';

import { useView } from '@/lib/store/hooks';
import Image, { ImageProps } from '../Image';
import { PageEntry } from '@/lib/parsers/entries/customEntryTypes';

export type PageImageProps = Omit<ImageProps, 'src'>;

export default function PageImage(props: PageImageProps) {
  const page = useView() as PageEntry;
  const entryImage = page?.entryImage ?? null;

  if (!entryImage) return null;

  return <Image alt="page-image" {...entryImage} {...props} />;
}
