import { DocumentAsset } from '@/lib/parsers/common';
import { makeNonNullableArray } from '@liquorice/utils';
import IconButton from '../IconButton';
import Box from '../ui/Box';
import Flex, { FlexProps } from '../ui/Flex';
import Txt from '../ui/Txt';
import * as styles from './DownloadButton.css';

export type DownloadButtonProps = FlexProps<
  'a',
  {
    title?: string;
    document?: Partial<DocumentAsset>;
  }
>;

const DownloadButton = ({ document = {}, title: customTitle, ...props }: DownloadButtonProps) => {
  const { url: href, extension, sizeHuman } = document;

  const title = customTitle ?? document.title;

  const ext = extension ? extension.toUpperCase() : null;
  const size = sizeHuman ?? null;
  const meta = makeNonNullableArray([ext, size]);

  const metaText = `(${meta.join(', ')})`.toUpperCase();
  const ariaLabel = `${title} ${metaText}`;

  return !href ? (
    <></>
  ) : (
    <Flex
      colorSet="white"
      paper
      direction="row"
      alignItems="center"
      columnGap="sm"
      bordered
      rounded
      as="a"
      download={'download'}
      aria-label={ariaLabel}
      href={href}
      target="_blank"
      className={styles.root}
      {...props}>
      <IconButton
        variant="filled"
        square
        rounded="large"
        disableHover
        color="primaryTintLighter"
        icon="download"
        className={styles.icon}
      />
      <Box>
        <Txt as="span" variant="small" weight="bold">
          {title}
        </Txt>
        <Flex direction="row" justifyContent="between">
          <Txt as="span" variant="small">
            {ext}
          </Txt>
          <Txt as="span" variant="small">
            {size}
          </Txt>
        </Flex>
      </Box>
    </Flex>
  );
};

export default DownloadButton;
