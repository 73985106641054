import { FAQ_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { toStringOrNull } from '@liquorice/utils';
import { FaqCardFragment } from '__generated__/graphql';

type FAQ = {
  heading?: string | null;
  content?: string;
};

export const parseFaq = (data: Maybe<FaqCardFragment>): FAQ | null => {
  if (!data) return null;

  const { heading, content } = data ?? {};

  return {
    heading: toStringOrNull(heading),
    content: cleanHtml(content),
  };
};

export const parseFaqFragment = createFragmentArrayParser(FAQ_CARD_FRAGMENT, (items) => {
  return items.map((item) => parseFaq(item));
});
