'use client';

import { useEventFAQ } from '@/layouts/EventLayout/EventLayout.Body';
import { Entry } from '@/lib/parsers/entries';
import { useProgramColorScheme, useView } from '@/lib/store/hooks';
import useShortlistStore from '@/lib/store/shortlist';
import { useTranslations } from 'next-intl';
import Breadcrumbs from '../Breadcrumbs';
import IconButton from '../IconButton';
import PageTicket from '../PageTicket';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Container from '../ui/Container';
import Grid from '../ui/Grid';
import Txt from '../ui/Txt';
import * as styles from './PageHeader.css';
import PageHeaderRoot from './PageHeaderRoot';
import PageMedia from './PageMedia';
import PageSubTitle from './PageSubTitle';

const PageHeaderEvent = () => {
  const t = useTranslations('entryIndex');
  const page = useView() as Entry<'event'>;
  const { status } = page;
  const disabled = status === 'expired';

  const { value: eventFaq, off: disableEventFaq, on: enableEventFaq } = useEventFAQ();
  const events = useShortlistStore((s) => s.events);
  const toggleEvent = useShortlistStore((s) => s.toggleEvent);
  const isShortlisted = events?.some((e) => e.id === page.id);

  const { colorScheme, oppositeScheme } = useProgramColorScheme();

  const defaultScheme = colorScheme || 'secondary';
  const colorSet = disabled ? 'notifyRed' : defaultScheme;

  return (
    <PageHeaderRoot
      variant="event"
      colorSet={colorSet}
      paper
      PageTitleProps={{
        variant: 'h2',
        bodyFont: true,
        weight: 'bold',
      }}
      GridProps={{ cx: { rowGap: 'md', pT: 'xl', pB: 'xl' } }}
      GridColProps={{ md: 8 }}
      beforeContainer={
        <Box className={styles.eventHero}>
          <Box cx={{ width: 'full', height: 'full' }}>
            <PageMedia
              ImageProps={{ priority: true, fixedRatio: true, className: styles.eventHeroImage }}
            />
          </Box>
          <Container cx={{ position: 'relative' }}>
            {disabled && (
              <Txt color="white" monoFont uppercase variant="tiny" className={styles.pastEventTag}>
                {t('pastEvent')}
              </Txt>
            )}
            <IconButton
              elevation="button"
              color="white"
              variant="text"
              icon={isShortlisted ? 'heart' : 'heartOutlined'}
              cx={{ mY: 'sm', mX: 'md' }}
              className={styles.eventShortlistButton}
              onClick={() => toggleEvent(page)}
            />
          </Container>
        </Box>
      }
      beforeTitle={
        <Breadcrumbs
          cx={{ mB: 'xs' }}
          CtaButtonStyle={{ color: disabled ? 'white' : oppositeScheme }}
          TxtProps={{ weight: 'bold' }}
        />
      }
      afterCol={
        <Grid.Col md={4}>
          <PageTicket TicketBtnProps={{ color: disabled ? 'error' : oppositeScheme }} />
        </Grid.Col>
      }
      afterContainer={
        <Container>
          <Grid>
            <Grid.Col sm={8} cx={{ display: 'flex', direction: 'row', columnGap: '6xs' }}>
              <Btn
                color="white"
                disableHover
                className={styles.eventFaqButton({ border: !eventFaq })}
                onClick={() => disableEventFaq()}>
                Description
              </Btn>
              <Btn
                color="white"
                disableHover
                className={styles.eventFaqButton({ border: eventFaq })}
                onClick={() => enableEventFaq()}>
                FAQs
              </Btn>
            </Grid.Col>
          </Grid>
        </Container>
      }>
      <PageSubTitle useGridCol={false} variant="body" />
    </PageHeaderRoot>
  );
};

export default PageHeaderEvent;
