'use client';

import { SEARCH_PARAM } from '@/components/EntryIndex/entryIndexConstants';
import { useEntryIndex } from '@/components/EntryIndex/useEntryIndex';
// import { useSearchMenu } from '@/components/Menu/useMenu';
import SearchBarResult from '@/components/SearchBar/SearchBar.Result';
import Box from '@/components/ui/Box';
import Divider from '@/components/ui/Divider';
import Txt from '@/components/ui/Txt';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import Btn from '../ui/Btn';
import * as styles from './SearchBar.css';

export type SearchBarResultsProps = {
  redirect: string;
};

const SearchBarResults = ({ redirect }: SearchBarResultsProps) => {
  const router = useRouter();
  // const { toggle: toggleSearchMenu } = useSearchMenu();

  const t = useTranslations('search');

  const loading = useEntryIndex((s) => s.loading);
  const totalItems = useEntryIndex((s) => s.totalItems);
  const items = useEntryIndex((s) => s.items);
  const search = useEntryIndex((s) => s.search);
  const hasSearch = useEntryIndex((s) => s.search && s.search.length > 2);
  const hasResults = items.length > 0;
  const showLoading = loading && hasSearch;

  const handleAllClick = () => {
    // toggleSearchMenu(false);
    const viewAllUrl = `/${redirect}?${SEARCH_PARAM}=${search}`;
    router.push(viewAllUrl, {});
  };

  return hasSearch ? (
    <Box className={styles.resultsWrap}>
      {hasResults && items.map((item) => <SearchBarResult key={item.id} item={item} />)}
      <Divider className={styles.divider} />
      {showLoading ? (
        <Txt variant="large">{t('loading', { query: search })}</Txt>
      ) : hasResults ? (
        <Box>
          <Btn className={styles.viewAllButton} onClick={handleAllClick} cx={{ fontSize: 'large' }}>
            {t('viewAll', { count: totalItems })}
          </Btn>
        </Box>
      ) : (
        <Txt variant="large">{t('noResults', { query: search })}</Txt>
      )}
    </Box>
  ) : null;
};

export default SearchBarResults;
