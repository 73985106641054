import BlockHeader from '@/components/BlockHeader';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';

const EventCreditsBlock = createBlock<'blocks_eventCredits_BlockType'>(
  ({ children = [], _blockMeta: meta }) => {
    return (
      <BlockContainer
        {...{
          meta,
          marginY: true,
          disableTransition: true,
        }}>
        {/*
          // FIXME: Temporary hack
          */}
        {children?.map((item: any, index: number) => (
          <BlockHeader
            key={index}
            {...{
              maxWidth: 'none',
              direction: 'row',
              justifyContent: 'between',
              heading: item.label,
              HeadingProps: { variant: 'body', weight: 'bold' },
              content: item.heading,
              ContentProps: { html: false, cx: { width: '50' } },
            }}
          />
        ))}
      </BlockContainer>
    );
  }
);

export default EventCreditsBlock;
