'use client';

import { parseCommonLink } from '@/lib/parsers/common';
import { isEntry } from '@/lib/parsers/entries';
import { useAppLink, useView } from '@/lib/store/hooks';
import { maybeGet } from '@liquorice/utils';
import CtaButton from '../CtaButton';
import Box, { BoxProps } from '../ui/Box';
import { BtnStyleProps } from '../ui/Btn';
import Txt, { TxtProps } from '../ui/Txt';

export type Breadcrumbs = BoxProps<
  'div',
  {
    CtaButtonStyle?: BtnStyleProps;
    TxtProps?: TxtProps;
  }
>;

export default function Breadcrumbs({ CtaButtonStyle, TxtProps, ...props }: Breadcrumbs) {
  const page = useView();

  const home = useAppLink('home');
  const articleIndex = useAppLink('articleIndex');
  const eventIndex = useAppLink('eventIndex');

  const maybeParent = maybeGet(page, 'parent');
  const parent = maybeParent ? parseCommonLink(maybeParent) : home;
  let ancestors = parent ? [parent] : [];

  switch (true) {
    case isEntry(page, 'article'):
      if (articleIndex) ancestors = [articleIndex];
      break;
    case isEntry(page, 'event'):
      if (eventIndex) ancestors = [eventIndex];
      break;
  }

  return (
    <Box {...props}>
      {ancestors?.map((v) => (
        <CtaButton
          key={v.id}
          href={v.uri ?? ''}
          endIcon={undefined}
          underline="none"
          StartIconProps={{ name: 'chevronLeft' }}
          {...CtaButtonStyle}>
          <Txt {...TxtProps}>{v.title}</Txt>
        </CtaButton>
      ))}
    </Box>
  );
}
