import Carousel from '@/components/Carousel';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import QuoteBlock from '../QuoteBlock';

const QuoteCarouselBlock = createBlock<'blocks_quoteCarousel_BlockType'>(
  ({ children = [], _blockMeta: meta }) => {
    const isQuoteCarousel = children?.length > 1;

    return (
      <BlockContainer
        {...{
          meta,
          marginY: true,
          disableTransition: true,
        }}>
        {!isQuoteCarousel ? (
          // FIXME: Temporary hack
          children?.map((v: any, i: number) => <QuoteBlock key={i} {...v} />)
        ) : (
          <Carousel
            disableNavigation
            style={{ cursor: 'grab' }}
            // FIXME: Temporary hack
            items={children?.map((v: any, i: number) => (
              <QuoteBlock key={i} {...v} />
            ))}
          />
        )}
      </BlockContainer>
    );
  }
);

export default QuoteCarouselBlock;
