import React from 'react';
import * as style from './ImageWithCaption.css';
import Box, { BoxProps } from '../ui/Box';
import Image, { ImageProps } from '../Image';
import Txt from '../ui/Txt';

export type ImageWithCaptionProps = BoxProps<
  'figure',
  {
    imageProps: ImageProps;
    caption?: string | null;
  }
>;

const ImageWithCaption = ({ imageProps, caption, ...props }: ImageWithCaptionProps) => {
  return (
    <Box as="figure" {...props}>
      <Image alt="" rounded {...imageProps} />
      <Txt as="figcaption" variant="small" color="neutralDark" className={style.caption}>
        {caption}
      </Txt>
    </Box>
  );
};

export default ImageWithCaption;
