import Video from '@/components/Video';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';

const VideoBlock = createBlock<'blocks_video_BlockType'>(({ videoUrl, _blockMeta: meta }) => {
  return (
    <BlockContainer
      {...{
        meta,
        marginY: true,
        disableTransition: true,
      }}>
      <Video videoUrl={videoUrl} />
    </BlockContainer>
  );
});

export default VideoBlock;
