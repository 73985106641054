'use client';

// import Swiper core and required modules
import { A11y, Pagination } from 'swiper/modules';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { useSwiper } from 'swiper/react';
import * as styles from './Carousel.css';

import { useBreakpointMin } from '@/theme';
import { useState } from 'react';
import IconButton from '../IconButton';
import Box from '../ui/Box';

const CarouselNavigation = () => {
  const swiper = useSwiper();

  const handleNext = () => {
    swiper.slideNext();
  };
  const handlePrev = () => {
    swiper.slidePrev();
  };

  return (
    <>
      <IconButton
        IconProps={{ className: styles.customIconSize }}
        className={styles.navigationPrev}
        elevation="button"
        variant="filled"
        size="normal"
        color="white"
        icon="chevronLeft"
        onClick={handlePrev}
      />
      <IconButton
        IconProps={{ className: styles.customIconSize }}
        className={styles.navigationNext}
        elevation="button"
        variant="filled"
        size="normal"
        color="white"
        icon="chevronRight"
        onClick={handleNext}
      />
    </>
  );
};

export type CarouselProps = {
  items: JSX.Element[];
  disableNavigation?: boolean;
  disablePagination?: boolean;
} & SwiperProps;

const Carousel = (props: CarouselProps) => {
  const { items = [], disableNavigation, disablePagination = false, ...rest } = props;

  const isMobile = !useBreakpointMin('md');
  const [_, setActiveIndex] = useState(0);

  return (
    <Swiper
      modules={[Pagination, A11y]}
      slidesPerView={1}
      slidesPerGroup={1}
      className={styles.root}
      onSlideChange={(s) => setActiveIndex(s.activeIndex)}
      watchSlidesProgress={true}
      watchOverflow={true}
      pagination={{
        clickable: true,
        enabled: !disablePagination,
      }}
      {...rest}>
      {items.map((item, i) => (
        <SwiperSlide key={i}>
          <Box>{item}</Box>
        </SwiperSlide>
      ))}
      {!disableNavigation &&
        !isMobile &&
        items.length >
          (rest.slidesPerView === 'auto' || rest.slidesPerView === undefined
            ? 1
            : rest.slidesPerView) && <CarouselNavigation />}
    </Swiper>
  );
};

export default Carousel;
