'use client';

import { Entry } from '@/lib/parsers/entries';
import { useView } from '@/lib/store/hooks';
import { useNextHydrated } from '@/lib/utils/createToggleStore';
import { fmtEventTime } from '@/lib/utils/format';
import Image from '../Image';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import VideoBackground from '../Video/VideoBackground';
import * as styles from './PageHeader.css';
import PageHeaderRoot from './PageHeaderRoot';

const PageHeaderHome = () => {
  const page = useView() as Entry<'home'>;
  // FIXME: temporary hack
  const event = page?.eventSingle as unknown as Entry<'event'>;
  const { entryTitle, entrySubTitle, eventTime, videoUrl, entryImage } = event ?? {};
  const time = fmtEventTime(eventTime);

  const nextHydrated = useNextHydrated();

  return (
    nextHydrated && (
      <PageHeaderRoot
        variant="home"
        className={styles.homeRoot}
        beforeContainer={
          <Box className={styles.imageWrapper}>
            {videoUrl ? (
              <VideoBackground videoUrl={videoUrl} />
            ) : entryImage ? (
              <Image alt="hero-event-image" priority fixedRatio {...entryImage} />
            ) : null}
          </Box>
        }>
        <Flex rowGap="md">
          {event && (
            <>
              {entryTitle && (
                <Txt variant="h1" color="white" monoFont cx={{ mB: 'none' }}>
                  {entryTitle}
                </Txt>
              )}
              <Flex>
                {entrySubTitle && (
                  <Txt color="white" cx={{ fontFamily: 'mono', mB: 'none' }}>
                    {entrySubTitle}
                  </Txt>
                )}
                {time && (
                  <Txt color="white" cx={{ fontFamily: 'mono', mB: 'none' }}>
                    {time}
                  </Txt>
                )}
              </Flex>
              {/* {eventLink && (
                <Flex direction="row">
                  <Btn color="white" variant="outlined" size="large" {...eventLink} />
                </Flex>
              )} */}
            </>
          )}
        </Flex>
      </PageHeaderRoot>
    )
  );
};

export default PageHeaderHome;
