'use client';

import Box from '@/components/ui/Box';
import Btn from '@/components/ui/Btn';
import Divider from '@/components/ui/Divider';
import Flex from '@/components/ui/Flex';
import { Category } from '@/lib/parsers/categories';
import { Entry } from '@/lib/parsers/entries';
import { useAppLink, useView } from '@/lib/store/hooks';

const EventLayoutDivider = () => {
  const eventIndex = useAppLink('eventIndex')?.uri;
  const page = useView() as Entry<'event'>;

  // FIXME: temporary hack
  const genreCategory = page.genreCategory as unknown as Category<'genreCategory'>[];

  return (
    <Box cx={{ mY: '3xl' }}>
      <Divider cx={{ color: 'primaryTintLight', mB: 'md' }} />
      <Flex direction="row" columnGap="xs">
        {genreCategory.map((genre) => {
          return (
            <Btn
              key={genre.id}
              size="tiny"
              variant="filled"
              color="primaryTintLighter"
              rounded="large"
              href={`${eventIndex}?${genre.groupHandle}=${genre.id}`}>
              {genre.title}
            </Btn>
          );
        })}
      </Flex>
    </Box>
  );
};

export default EventLayoutDivider;
