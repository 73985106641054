import Icon from '@/components/Icon';
import NextAnchor from '@/components/ui/NextAnchor';
import Txt from '@/components/ui/Txt';
import { Entry, isEntry } from '@/lib/parsers/entries';
import * as styles from './SearchBar.css';

type SearchBarResultProps = {
  item: Entry;
};

const SearchBarResult = (props: SearchBarResultProps) => {
  const { item } = props;
  const { title, uri, parent } = item;
  const parentTitle = parent?.title ?? '';

  let typeLabel = '';

  switch (true) {
    case isEntry(item, 'article'):
      typeLabel = 'Article';
      break;
    case isEntry(item, 'event'):
      typeLabel = 'Event';
      break;
    default:
      typeLabel = 'Page';
      break;
  }

  return (
    <NextAnchor href={uri} className={styles.resultItem}>
      <Txt variant="large" className={styles.resultItemLabel}>
        {parentTitle && <span>{parentTitle} / </span>}
        {title}
      </Txt>
      <Txt variant="large" className={styles.resultItemType}>
        {typeLabel}
      </Txt>
      <Icon className={styles.resultItemIcon} name="chevronRight" />
    </NextAnchor>
  );
};

export default SearchBarResult;
