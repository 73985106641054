'use client';

import Blocks from '@/components/Blocks';
import { createToggleStore } from '@/lib/utils/createToggleStore';
import EventLayoutDivider from './EventLayout.Divider';
import EventLayoutFaq from './EventLayout.Faq';

export const useEventFAQ = createToggleStore(false, 'devMode');

const EventLayoutBody = () => {
  const { value: eventFaqEnabled } = useEventFAQ();

  return (
    <>
      {eventFaqEnabled ? <EventLayoutFaq /> : <Blocks key="blocks" />}
      <EventLayoutDivider key="divider" />
    </>
  );
};

export default EventLayoutBody;
