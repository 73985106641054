'use client';

import { Entry } from '@/lib/parsers/entries';
import { PageEntry } from '@/lib/parsers/entries/customEntryTypes';
import { fmtEventTime } from '@/lib/utils/format';
import { useBreakpointMax } from '@/theme';
import { maybeGet } from '@liquorice/utils';
import { useTranslations } from 'next-intl';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Container from '../ui/Container';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import * as styles from './FeaturedCard.css';
import { useNextHydrated } from '@/lib/utils/createToggleStore';

const FeaturedEventCard = (props: Entry) => {
  const t = useTranslations();
  const { entryTitle, uri, postDate } = props as PageEntry;

  const maybeDate = maybeGet(props, 'eventTime');
  const date = fmtEventTime(maybeDate) ?? postDate;

  const nextHydrated = useNextHydrated();

  const md = useBreakpointMax('md');
  const sm = useBreakpointMax('sm');

  return (
    nextHydrated && (
      <Box className={styles.contentWrapper}>
        <Container maxWidth="xl" disablePadding={sm}>
          <Box
            className={styles.content({ variant: 'event' })}
            cx={{ p: 'sm', mT: 'md' }}
            colorSet="primaryAlt"
            paper>
            <Txt as="p" variant="large" cx={{ fontWeight: 'bold' }}>
              {entryTitle}
            </Txt>
            <Txt as="p" uppercase cx={{ fontFamily: 'mono', fontSize: 'small' }}>
              {date}
            </Txt>
            <Flex columnGap="xs" rowGap="xs" direction={md ? 'row' : 'column'}>
              <Btn
                href={uri}
                variant="outlined"
                color="secondary"
                weight="bold"
                rounded="small"
                fullWidth={true}>
                {t('common.moreInfo')}
              </Btn>
              <Btn
                weight="bold"
                variant="filled"
                color="secondary"
                rounded="small"
                fullWidth={true}
                startIcon="ticket">
                {t('actions.buyTickets')}
              </Btn>
            </Flex>
          </Box>
        </Container>
      </Box>
    )
  );
};

export default FeaturedEventCard;
