import { createBlock } from '@/lib/parsers/blocks';
import { parseColorScheme, parseLinkField } from '@/lib/parsers/common';
import { PageEntry } from '@/lib/parsers/entries/customEntryTypes';
import { parseImage } from '@/lib/parsers/images';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { toStringOrNull } from '@liquorice/utils';

/** Hook to apply card "overrides" and extra features */
export const useExternalCard = createBlock<
  'blocks_externalCard_BlockType',
  NoProps,
  PageEntry | null
>((props) => {
  const { heading, content, linkField, colorScheme, imageSingle } = props;

  const maybeHeading = toStringOrNull(heading);
  const maybeContent = cleanHtml(content);
  const maybeLink = parseLinkField(linkField);
  const maybeColorScheme = parseColorScheme(colorScheme);
  const maybeImage = parseImage(imageSingle);

  const item = {
    entryTitle: maybeHeading,
    entrySummary: maybeContent,
    entryImage: maybeImage,
    colorScheme: maybeColorScheme,
    uri: maybeLink?.href,
  } as PageEntry;

  // Bail early
  if (!item) return null;

  return item as PageEntry;
});
