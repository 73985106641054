import BlockHeader from '@/components/BlockHeader';
import SimpleGrid from '@/components/SimpleGrid';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import { ExternalCardBlockDefault } from '../ExternalCardBlock/ExternalCardBlock.Default';
import { SimpleCardBlock } from '../SimpleCardBlock';

export const EntryCardsBlock = createBlock<'blocks_entryCards_BlockType'>(
  ({ _blockMeta: meta, children = [], heading }) => {
    return (
      <BlockContainer {...{ meta, marginY: true, disableTransition: true }}>
        <BlockHeader
          heading={heading}
          HeadingProps={{ cx: { mB: 'xl' }, autoMargin: false, color: 'primary' }}
        />
        <SimpleGrid
          md={6}
          lg={4}
          rowGutter={false}
          cx={{ justifyContent: 'center', rowGap: { xs: 'xl', lg: '3xl' } }}>
          {/*
          // FIXME: Temporary hack
          */}
          {children?.map((item: any, index: number) =>
            item?.__typename === 'blocks_externalCard_BlockType' ? (
              <ExternalCardBlockDefault key={index} {...item} />
            ) : (
              <SimpleCardBlock key={index} {...item} />
            )
          )}
        </SimpleGrid>
      </BlockContainer>
    );
  }
);

export default EntryCardsBlock;
