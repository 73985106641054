import BlockHeader from '@/components/BlockHeader';
import { BlockContainer } from '@/components/Blocks';
import Box from '@/components/ui/Box';
import Btn from '@/components/ui/Btn';
import { createBlock } from '@/lib/parsers/blocks';
import * as styles from './RichTextBlock.css';

const RichText = createBlock<'blocks_richText_BlockType'>(
  ({ heading, content, linkFieldMultiple, _blockMeta: meta }) => {
    return (
      <BlockContainer
        {...{
          meta,
          marginY: true,
          disableTransition: true,
        }}>
        <BlockHeader
          maxWidth="none"
          heading={heading}
          HeadingProps={{ variant: 'h3', color: 'primary', cx: { mB: 'md' } }}
          content={content}
          ContentProps={{ className: styles.withRichText }}>
          <Box cx={{ width: { md: '50' } }}>
            {linkFieldMultiple &&
              !!linkFieldMultiple.length &&
              // FIXME: Temporary hack
              linkFieldMultiple.map((link: any, index: number) => {
                return (
                  <Btn
                    key={index}
                    cx={{ mT: 'xl' }}
                    fullWidth
                    variant="filled"
                    color="primary"
                    size="large"
                    rounded="small"
                    {...link}
                  />
                );
              })}
          </Box>
        </BlockHeader>
      </BlockContainer>
    );
  }
);

export default RichText;
