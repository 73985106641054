import BlockHeader from '@/components/BlockHeader';
import Carousel from '@/components/Carousel';
import EntryCard from '@/components/EntryCard';
import { useEventIndexEntries } from '@/lib/getters/useEventIndexEntries';
import { createBlock } from '@/lib/parsers/blocks';
import { fmtCategoryIds } from '@/lib/utils/format';
import { BlockContainer } from '../..';
import { useTranslations } from 'next-intl';
import SkeletonCards from '@/components/SkeletonCards';
import Txt from '@/components/ui/Txt';
import CtaButton from '@/components/CtaButton';
import { useAppLink } from '@/lib/store/hooks';
import { useBreakpointMin } from '@/theme';
import { Category } from '@/lib/parsers/categories';

export const EventIndexBlock = createBlock<'blocks_eventIndex_BlockType'>(
  ({
    venueCategory: venue,
    programCategory: program,
    accessibilityCategory: accessibility,
    eventTypeCategory: eventType,
    genreCategory: genre,
    dateRange,
    latestEvents: useCurrentDate,
    heading,
    _blockMeta: meta,
  }) => {
    const t = useTranslations();
    const genreCategory = fmtCategoryIds(genre as unknown as Category<'genreCategory'>);
    const venueCategory = fmtCategoryIds(venue as unknown as Category<'venueCategory'>);
    const programCategory = fmtCategoryIds(program as unknown as Category<'programCategory'>);
    const accessibilityCategory = fmtCategoryIds(
      accessibility as unknown as Category<'accessibilityCategory'>
    );
    const eventTypeCategory = fmtCategoryIds(eventType as unknown as Category<'eventTypeCategory'>);

    const eventIndex = useAppLink('eventIndex');

    const { result, loading, error } =
      useEventIndexEntries(
        {
          genreCategory,
          venueCategory,
          programCategory,
          accessibilityCategory,
          eventTypeCategory,
        },
        dateRange,
        useCurrentDate
      ) ?? {};

    const hasResults = result?.length && (result?.length ?? 0) > 0;

    const md = useBreakpointMin('md');

    if (error) return null;

    return (
      <BlockContainer
        {...{
          colorSet: 'white',
          paper: true,
          meta,
          marginY: true,
          disableTransition: true,
          overflow: true,
        }}>
        <BlockHeader
          maxWidth="none"
          heading={heading}
          HeadingProps={{ variant: 'h3', cx: { m: 'none' } }}
          cx={{ mB: 'md' }}
          direction="row"
          alignItems="center"
          justifyContent="between">
          <CtaButton href={eventIndex?.uri ?? ''} underline="always">
            {t('actions.readMore')}
          </CtaButton>
        </BlockHeader>
        {loading ? (
          <SkeletonCards
            amount={md ? 3 : 1}
            md={6}
            lg={4}
            SkeletonItems={{
              items: [
                { variant: 'rounded', height: 200 },
                { variant: 'rounded', height: 30, width: '90%' },
                { variant: 'rounded', height: 15, width: '50%' },
              ],
            }}
          />
        ) : hasResults ? (
          <Carousel
            slidesPerView={md ? 3 : 1}
            slidesPerGroup={md ? 3 : 1}
            spaceBetween={24}
            items={result?.map((v) => (
              <EntryCard key={v.id} item={v} />
            ))}
          />
        ) : (
          <Txt>{t('search.noResults', { query: heading })}</Txt>
        )}
      </BlockContainer>
    );
  }
);

export default EventIndexBlock;
