import ImageWithCaption from '@/components/ImageWithCaption';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';

const ImageBlock = createBlock<'blocks_image_BlockType'>(({ imageSingle, caption, _blockMeta }) => {
  return (
    <BlockContainer meta={_blockMeta} marginY disableTransition>
      <ImageWithCaption
        imageProps={{
          ...imageSingle,
          rounded: false,
          // ratio: 'landscape',
          fixedRatio: true,
        }}
        caption={caption}
      />
    </BlockContainer>
  );
});

export default ImageBlock;
