import Divider from '@/components/ui/Divider';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../../BlockContainer';

export const DividerBlock = createBlock<'blocks_divider_BlockType'>(({ _blockMeta: meta }) => {
  return (
    <BlockContainer {...{ meta, marginY: true, disableTransition: true }}>
      <Divider cx={{ color: 'primaryTintLight' }} />
    </BlockContainer>
  );
});

export default DividerBlock;
