import FeaturedCard from '@/components/FeaturedCard/FeaturedCard';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import { Entry } from '@/lib/parsers/entries';

export const FeaturedEventBlock = createBlock<'blocks_featuredEvent_BlockType'>(
  ({ eventSingle, _blockMeta: meta }) => {
    return (
      <BlockContainer
        {...{
          meta,
          maxWidth: 'mx',
          marginY: 'none',
          disableTransition: true,
          ContainerProps: { disablePadding: true },
        }}>
        <FeaturedCard {...(eventSingle as Entry)} />
      </BlockContainer>
    );
  }
);

export default FeaturedEventBlock;
