import BlockHeader from '@/components/BlockHeader';
import { BlockContainer } from '@/components/Blocks';
import CtaButton from '@/components/CtaButton';
import Image from '@/components/Image';
import Box from '@/components/ui/Box';
import Grid from '@/components/ui/Grid';
import { createBlock } from '@/lib/parsers/blocks';

const ImageAndTextBlock = createBlock<'blocks_imageAndText_BlockType'>(
  ({
    heading,
    content,
    linkField,
    flipAlignment,
    imageSingle,
    lightswitch: quarterImage,
    _blockMeta: meta,
  }) => {
    return (
      <BlockContainer {...{ meta, disableTransition: true, marginY: true }}>
        <Grid
          cx={{
            alignItems: 'center',
            justifyContent: 'between',
            flexDirection: { md: flipAlignment ? 'row-reverse' : 'row' },
          }}>
          <Grid.Col md={quarterImage ? 9 : 6}>
            <BlockHeader
              cx={{ p: '2xs' }}
              maxWidth="none"
              heading={heading}
              HeadingProps={{ variant: 'h5', color: 'primary', cx: { mB: 'md' } }}
              content={content}>
              {linkField && (
                <CtaButton variant="outlined" size="small" {...linkField} cx={{ mT: 'md' }} />
              )}
            </BlockHeader>
          </Grid.Col>
          <Grid.Col md={quarterImage ? 3 : 6}>
            <Box cx={{ p: '2xs' }}>
              <Image alt="" {...imageSingle} />
            </Box>
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default ImageAndTextBlock;
