import Image from '@/components/Image';
import Box from '@/components/ui/Box';
import Divider from '@/components/ui/Divider';
import Flex from '@/components/ui/Flex';
import Txt from '@/components/ui/Txt';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import * as styles from './LogoAndTextBlock.css';

export const LogoAndTextBlock = createBlock<'blocks_logoAndText_BlockType'>(
  ({ heading, imageMultiple, lightswitch: captions, _blockMeta: meta }) => {
    return (
      <BlockContainer
        {...{ colorSet: 'white', paper: true, meta, marginY: true, disableTransition: true }}>
        <Txt as="span" variant="h5">
          {heading}
        </Txt>
        <Divider direction="horizontal" cx={{ mY: 'md', color: 'primaryTintLight' }} />
        <Flex direction="row" columnGap="lg" alignItems="end">
          {/*
  // FIXME: Temporary hack
        */}
          {imageMultiple?.map((image: any, i: number) => {
            return (
              <Box key={i} cx={{ width: { xs: '50', md: '25' } }}>
                {captions && image.alt && (
                  <Txt color="black" as="figcaption" cx={{ mB: 'md' }}>
                    {image.alt}
                  </Txt>
                )}
                <Box className={styles.imageWrapper}>
                  <Image alt="" fixedRatio contain {...image} />
                </Box>
              </Box>
            );
          })}
        </Flex>
      </BlockContainer>
    );
  }
);

export default LogoAndTextBlock;
