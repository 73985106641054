import Card from '@/components/Card';
import Grid from '@/components/ui/Grid';
import { createBlock } from '@/lib/parsers/blocks';
import { parseColorScheme, parseLinkField } from '@/lib/parsers/common';
import { parseImage } from '@/lib/parsers/images';
import { useViewColorScheme } from '@/lib/store/hooks';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { toStringOrNull } from '@liquorice/utils';

export const ExternalCardBlockOverview = createBlock<'blocks_externalCard_BlockType'>(
  ({ heading, content, linkField, imageSingle, colorScheme }) => {
    const link = parseLinkField(linkField);
    const newWindow = link?.target === '_blank';
    const { colorScheme: colorSet, oppositeScheme } = useViewColorScheme(
      parseColorScheme(colorScheme)
    );
    const title = toStringOrNull(heading);
    const description = cleanHtml(content);
    const image = parseImage(imageSingle);

    return (
      <Card
        {...{
          colorSet,
          paper: true,
          fullHeight: true,
          elevation: false,
          padding: 'lg',
          item: {
            title,
            description,
            image,
            href: link?.href,
            customText: link?.children,
          },
          newWindow,
        }}>
        <Grid>
          <Grid.Col>
            <Card.Image mask={true} ratio="landscape" />
          </Grid.Col>
          <Grid.Col>
            <Card.Body>
              <Card.Title variant="h4" cx={{ mB: 'none' }} />
              <Card.Description color={oppositeScheme} />
              <Card.Cta variant="filled" color={oppositeScheme} rounded="small" />
            </Card.Body>
          </Grid.Col>
        </Grid>
      </Card>
    );
  }
);

export default ExternalCardBlockOverview;
