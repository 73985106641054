import OverviewCard from '@/components/OverviewCard';
import { createBlock } from '@/lib/parsers/blocks';
import { useOverviewCard } from './useOverviewCard';
import { useExternalCard } from '../ExternalCardBlock/useExternalCard';

export const OverviewCardBlock = createBlock<'blocks_overviewCard_BlockType'>(
  ({ __typename, ...props }) => {
    let item = null;

    if (__typename === 'blocks_overviewCard_BlockType') item = useOverviewCard(props);
    if (__typename === 'blocks_externalCard_BlockType') item = useExternalCard(props);

    if (!item) return null;

    return <OverviewCard item={item} />;
  }
);

export default OverviewCardBlock;
