'use client';

import Breadcrumbs from '../Breadcrumbs';
import PageHeaderRoot, { PageHeaderRootProps } from './PageHeaderRoot';

export type PageHeaderIndexProps = Partial<PageHeaderRootProps>;

const PageHeaderIndex = ({ children, ...props }: PageHeaderIndexProps) => {
  return (
    <PageHeaderRoot
      variant="index"
      colorSet="primaryAlt"
      paper
      beforeTitle={<Breadcrumbs CtaButtonStyle={{ color: 'secondary' }} cx={{ mB: 'xs' }} />}
      PageTitleProps={{ bodyFont: true, weight: 'bold', uppercase: true }}
      {...props}>
      {children}
    </PageHeaderRoot>
  );
};

export default PageHeaderIndex;
