'use client';

import { useView } from '@/lib/store/hooks';
import Image, { ImageProps } from '../Image';
import { PageEntry } from '@/lib/parsers/entries/customEntryTypes';
import { maybeGet } from '@liquorice/utils';
import VideoBackground, { VideoBackgroundProps } from '../Video/VideoBackground';

export type PageMediaProps = {
  ImageProps?: Omit<ImageProps, 'src'>;
  VideoProps?: VideoBackgroundProps;
};

export default function PageMedia({ ImageProps, VideoProps }: PageMediaProps) {
  const page = useView() as PageEntry;
  const entryVideo = maybeGet(page, 'videoUrl');
  const entryImage = page?.entryImage ?? null;

  if (!entryImage && !entryVideo) return null;

  return entryVideo ? (
    <VideoBackground videoUrl={entryVideo} {...VideoProps} />
  ) : entryImage ? (
    <Image alt="page-image" priority {...entryImage} {...ImageProps} />
  ) : null;
}
