import { CATEGORY_QUERY } from '@/gql/queries/categoryQueries.gql';
import { useQuery } from '@apollo/client';
import { CategoryQueryVariables } from '__generated__/graphql';
import { Category } from '../parsers/categories';

export const useCategory = (variables: CategoryQueryVariables) => {
  const { data, loading, error } = useQuery(CATEGORY_QUERY, {
    variables,
  });
  if (error) return null && console.log(`An error occurred with the query: ${error.message}`);

  // FIXME: temporary hack
  const result = data?.category as unknown as Category;

  return {
    result,
    loading,
    error,
  };
};
