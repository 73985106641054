import BlockHeader from '@/components/BlockHeader';
import SearchBar from '@/components/SearchBar';
import Btn from '@/components/ui/Btn';
import Flex from '@/components/ui/Flex';
import Grid from '@/components/ui/Grid';
import { createBlock } from '@/lib/parsers/blocks';
import { useAppLink } from '@/lib/store/hooks';
import { BlockContainer } from '../..';

export const EventSearchBlock = createBlock<'blocks_eventSearch_BlockType'>(
  ({ heading, linkFieldMultiple, _blockMeta: meta }) => {
    const eventIndex = useAppLink('eventIndex');

    return (
      <BlockContainer
        {...{
          colorSet: 'primaryTintLighter',
          paper: true,
          meta,
          marginY: 'none',
          paddingY: true,
          disableTransition: true,
          overflow: true,
        }}>
        <Grid>
          <Grid.Col offsetMd={3} md={6}>
            <BlockHeader
              maxWidth="none"
              heading={heading}
              alignItems="center"
              HeadingProps={{ variant: 'h3', cx: { mB: 'md' } }}
            />
            <SearchBar
              searchableEntryTypes="event"
              redirect="whats-on/all-events"
              cx={{ mB: 'md' }}
            />
            <Flex direction="row" justifyContent="between" wrap="wrap">
              <Btn
                variant="text"
                color="primary"
                underline="always"
                href={eventIndex?.uri ?? ''}
                startIcon="chevronLeft">
                {eventIndex?.title}
              </Btn>
              <Flex direction="row" columnGap="sm">
                {/*
                // FIXME: Temporary hack
                 */}
                {linkFieldMultiple?.map((link: any, i: number) => (
                  <Btn
                    key={i}
                    variant="text"
                    cx={{ fontSize: 'small' }}
                    color="primaryTintDark"
                    underline="always"
                    {...link}
                  />
                ))}
              </Flex>
            </Flex>
          </Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default EventSearchBlock;
