import BlockHeader from '@/components/BlockHeader';
import Formie from '@/components/Formie';
import Grid from '@/components/ui/Grid';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../../BlockContainer';

export const FormBlock = createBlock<'blocks_form_BlockType'>(
  ({ heading, content, form, _blockMeta: meta }) => {
    return (
      <BlockContainer
        {...{
          meta,
          disableTransition: true,
          marginY: true,
        }}>
        <Grid rowGutter={false} cx={{ rowGap: 'xl' }}>
          <Grid.Col>
            <BlockHeader
              maxWidth="none"
              heading={heading}
              HeadingProps={{ variant: 'h3', color: 'primary', cx: { mB: 'md' } }}
              content={content}
            />
          </Grid.Col>
          <Grid.Col>{!!form && <Formie form={form} />}</Grid.Col>
        </Grid>
      </BlockContainer>
    );
  }
);

export default FormBlock;
