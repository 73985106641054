'use client';

import { useViewColorScheme } from '@/lib/store/hooks';
import { useBreakpointMin } from '@/theme';
import Breadcrumbs from '../Breadcrumbs';
import Grid from '../ui/Grid';
import PageHeaderRoot from './PageHeaderRoot';
import PageImage from './PageImage';

const PageHeaderOverview = () => {
  const { colorScheme, oppositeScheme } = useViewColorScheme();
  const sm = useBreakpointMin('sm');

  return (
    <PageHeaderRoot
      variant="overview"
      colorSet={colorScheme}
      paper
      GridColProps={{ md: 8 }}
      beforeTitle={
        <Breadcrumbs CtaButtonStyle={{ color: oppositeScheme }} cx={{ mB: 'xs', mT: '5xl' }} />
      }
      PageTitleProps={{ cx: { mB: '5xl' }, monoFont: true, uppercase: true }}
      afterCol={
        sm && (
          <Grid.Col offsetMd={1} sm={5} md={3}>
            <PageImage mask={true} />
          </Grid.Col>
        )
      }
    />
  );
};

export default PageHeaderOverview;
