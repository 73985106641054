import BlockHeader from '@/components/BlockHeader';
import Flex from '@/components/ui/Flex';
import Txt from '@/components/ui/Txt';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../../BlockContainer';

export const QuoteBlock = createBlock<'blocks_quote_BlockType'>(
  ({ heading, label, _blockMeta: meta }) => {
    return (
      <BlockContainer
        {...{
          meta,
          marginY: true,
          disableTransition: true,
        }}>
        <Flex colorSet="white" paper justifyContent="center" alignItems="center" cx={{ pX: 'md' }}>
          <BlockHeader
            maxWidth="none"
            heading={heading}
            HeadingProps={{ variant: 'h3', cx: { textAlign: 'center' } }}>
            <Txt cx={{ textAlign: 'center', width: 'full' }}>{label}</Txt>
          </BlockHeader>
        </Flex>
      </BlockContainer>
    );
  }
);

export default QuoteBlock;
