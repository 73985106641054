import { useExpandingCard } from '@/components/Blocks/BlockComponents/ExpandingCardBlock/useExpandingCard';
import { createBlock } from '@/lib/parsers/blocks';
import { useExternalCard } from '../ExternalCardBlock/useExternalCard';
import ExpandingCard from '@/components/ExpandingCard';

export const ExpandingCardBlock = createBlock<'blocks_expandingCard_BlockType'>(
  ({ flipAlignment, __typename, ...props }) => {
    let item = null;

    if (__typename === 'blocks_expandingCard_BlockType') item = useExpandingCard(props);
    if (__typename === 'blocks_externalCard_BlockType') item = useExternalCard(props);

    if (!item) return null;

    return <ExpandingCard item={item} flipAlignment={flipAlignment} />;
  }
);

export default ExpandingCardBlock;
