'use client';

import { useView } from '@/lib/store/hooks';
import { PageHeaderProps } from '.';
import Grid, { GridColProps } from '../ui/Grid';
import Txt, { TxtProps } from '../ui/Txt';
import { PageEntry } from '@/lib/parsers/entries/customEntryTypes';

export type PageSummaryProps = TxtProps<
  'span',
  {
    GridColProps?: GridColProps;
    useGridCol?: boolean;
  }
> &
  Partial<PageHeaderProps>;

export default function PageSummary({
  children,
  GridColProps,
  useGridCol = true,
  ...props
}: PageSummaryProps) {
  const page = useView() as PageEntry;
  const summary = page?.entrySummary ?? null;

  const content = children || summary;

  const Content = () =>
    content && (
      <Txt as="span" variant="large" {...props}>
        {content}
      </Txt>
    );

  return useGridCol && content ? (
    <Grid.Col md={8} {...GridColProps}>
      <Content />
    </Grid.Col>
  ) : (
    <Content />
  );
}
