'use client';

import { Entry } from '@/lib/parsers/entries';
import { PageEntry } from '@/lib/parsers/entries/customEntryTypes';
import { fmtEventTime } from '@/lib/utils/format';
import { useBreakpointMax } from '@/theme';
import { maybeGet } from '@liquorice/utils';
import { useTranslations } from 'next-intl';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Container from '../ui/Container';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import * as styles from './FeaturedCard.css';
import { useNextHydrated } from '@/lib/utils/createToggleStore';

const FeaturedDefaultCard = (props: Entry) => {
  const t = useTranslations('common');
  const { entryTitle, uri, postDate } = props as PageEntry;

  const maybeDate = maybeGet(props, 'eventTime');
  const date = fmtEventTime(maybeDate) ?? postDate;

  const nextHydrated = useNextHydrated();

  const sm = useBreakpointMax('sm');

  return (
    nextHydrated && (
      <Box className={styles.contentWrapper}>
        <Container maxWidth="xl" disablePadding={sm}>
          <Box
            className={styles.content()}
            cx={{ p: 'sm', mT: 'md' }}
            colorSet="primaryTintLighter"
            paper>
            <Txt as="p" variant="h4">
              {entryTitle}
            </Txt>
            <Flex columnGap="xs" rowGap="xs" direction="row" justifyContent="between">
              <Txt as="span" uppercase cx={{ fontFamily: 'mono', fontSize: 'small' }}>
                {date}
              </Txt>
              <Btn
                href={uri}
                variant="text"
                color="primary"
                weight="bold"
                underline="always"
                EndIconProps={{ name: 'chevronRight', cx: { fontSize: 'h4' } }}>
                {t('moreInfo')}
              </Btn>
            </Flex>
          </Box>
        </Container>
      </Box>
    )
  );
};

export default FeaturedDefaultCard;
