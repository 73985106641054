import { Sprinkles } from '@/theme/sprinkles.css';
import Box, { BoxProps } from '../ui/Box';
import Container, { ContainerProps } from '../ui/Container';
import Grid, { GridColProps, GridProps } from '../ui/Grid';
import PageTitle, { PageTitleProps } from './PageTitle';

export type PageHeaderRootProps = BoxProps<
  'header',
  {
    PageTitleProps?: PageTitleProps;
    variant?: 'home' | 'overview' | 'index' | 'article' | 'standard' | 'event' | 'educationalEvent';
    maxWidth?: ContainerProps['maxWidth'];
    show?: {
      date?: boolean;
      author?: boolean;
    };
    beforeTitle?: React.ReactNode;
    afterGrid?: React.ReactNode;
    afterCol?: React.ReactNode;
    beforeCol?: React.ReactNode;
    afterContainer?: React.ReactNode;
    beforeContainer?: React.ReactNode;
    GridProps?: GridProps;
    GridColProps?: GridColProps;
    ContainerProps?: ContainerProps;
  }
>;

const PageHeaderRoot = ({
  PageTitleProps,
  children,
  variant = 'standard',
  maxWidth,
  beforeTitle,
  afterGrid,
  afterCol,
  beforeCol,
  afterContainer,
  beforeContainer,
  cx: customCx,
  GridProps: customGridProps,
  GridColProps,
  ContainerProps,
  ...props
}: PageHeaderRootProps) => {
  const cx: Sprinkles = {
    position: 'relative',
    overflow: 'hidden',
    ...customCx,
  };

  const GridProps: GridProps = {
    cx: {
      rowGap: 'xl',
      pY: '5xl',
    },
    ...((variant === 'overview' || variant === 'standard') && {
      cx: {
        pY: 'none',
      },
    }),
    ...customGridProps,
  };

  const isHomePage = variant === 'home';

  return (
    <Box as="header" {...props} cx={cx}>
      {beforeContainer}
      <Container maxWidth={maxWidth} {...ContainerProps}>
        <Grid {...GridProps}>
          {beforeCol}
          <Grid.Col {...GridColProps}>
            {beforeTitle}
            {!isHomePage && <PageTitle {...PageTitleProps} />}
            {children}
          </Grid.Col>
          {afterCol}
        </Grid>
        {afterGrid}
      </Container>
      {afterContainer}
    </Box>
  );
};

export default PageHeaderRoot;
