import Btn from '@/components/ui/Btn';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import { Alert, CircularProgress, Collapse, Stack } from '@mui/material';
import { useTranslations } from 'next-intl';
import Box from '../ui/Box';

type FormieSubmitRowProps = {
  // methods: UseFormReturn; //<FormValues, any, undefined>
  // form: ParsedForm;
  isSuccess?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  messages?: {
    error: string;
    success: string;
  };
};

export const FormieSubmitRow = ({
  isSuccess,
  isError,
  isLoading,
  messages,
}: FormieSubmitRowProps) => {
  const t = useTranslations('actions');

  const showLoading = isLoading;
  const showErrors = isError && !isLoading;
  const spacing = 4;

  return (
    <Box>
      <Collapse in={showErrors} unmountOnExit>
        <Alert severity="error" sx={{ mb: spacing }}>
          <Txt html variant="small">
            {messages?.error}
          </Txt>
        </Alert>
      </Collapse>
      <Collapse in={isSuccess} unmountOnExit>
        <Alert severity="success" sx={{ mb: spacing }}>
          <Txt html>{messages?.success}</Txt>
        </Alert>
      </Collapse>
      <Stack textAlign="left">
        <Grid>
          <Grid.Col xs={6}>
            <Btn type="submit" variant="filled" disabled={isLoading}>
              {t('submit')}
            </Btn>
          </Grid.Col>
          <Grid.Col xs={6} cx={{ textAlign: 'right' }}>
            {showLoading && <CircularProgress color="inherit" size={40} />}
          </Grid.Col>
        </Grid>
      </Stack>
    </Box>
  );
};
