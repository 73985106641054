'use client';

import { useViewColorScheme } from '@/lib/store/hooks';
import { useBreakpointMin } from '@/theme';
import Breadcrumbs from '../Breadcrumbs';
import Grid from '../ui/Grid';
import PageHeaderRoot from './PageHeaderRoot';
import PageImage from './PageImage';
import PageSummary from './PageSummary';

const PageHeaderStandard = () => {
  const { colorScheme, oppositeScheme } = useViewColorScheme();
  const sm = useBreakpointMin('sm');

  return (
    <PageHeaderRoot
      variant="standard"
      colorSet={colorScheme}
      paper
      GridColProps={{ xs: 12, sm: 7, md: 8, cx: { mY: '5xl' } }}
      beforeTitle={<Breadcrumbs CtaButtonStyle={{ color: oppositeScheme }} cx={{ mB: 'xs' }} />}
      PageTitleProps={{ monoFont: true, uppercase: true, cx: { mB: 'xs' } }}
      afterCol={
        sm && (
          <Grid.Col offsetMd={1} sm={5} md={3}>
            <PageImage mask={true} />
          </Grid.Col>
        )
      }>
      <PageSummary weight="bold" useGridCol={false} />
    </PageHeaderRoot>
  );
};

export default PageHeaderStandard;
