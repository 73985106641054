'use client';

import Card from '@/components/Card';
import { EntryCardProps } from '@/components/EntryCard/EntryCardProps';
import Box from '@/components/ui/Box';
import Flex from '@/components/ui/Flex';
import { PageEntry } from '@/lib/parsers/entries/customEntryTypes';
import { useViewColorScheme } from '@/lib/store/hooks';
import { useBreakpointMax } from '@/theme';
import Grid from '../ui/Grid';
import { maybeGet } from '@liquorice/utils';

export type OverviewCardProps = EntryCardProps;

const OverviewCard = ({ item, ...props }: OverviewCardProps) => {
  const {
    uri: href,
    title,
    entryTitle,
    entrySummary: description,
    entryImage: image,
  } = item as PageEntry;

  const colorScheme = maybeGet(item, 'colorScheme');
  const { colorScheme: colorSet, oppositeScheme } = useViewColorScheme(colorScheme);
  const md = useBreakpointMax('md');

  return (
    <Card
      colorSet={colorSet}
      paper
      elevation={false}
      item={{
        title: entryTitle ?? title,
        description,
        image,
        href,
      }}
      {...props}>
      <Grid>
        {md && (
          <Grid.Col>
            <Card.Image ratio="square" />
          </Grid.Col>
        )}
        <Grid.Col md={7}>
          <Flex cx={{ p: 'lg' }} rowGap="md">
            <Card.Title as="span" />
            <Card.Description variant="large" color={oppositeScheme} />
            <Box cx={{ width: '50' }}>
              <Card.Cta color={oppositeScheme} variant="filled" size="large" fullWidth />
            </Box>
          </Flex>
        </Grid.Col>
        {!md && (
          <Grid.Col offsetMd={1} md={3}>
            <Card.Image mask fixedRatio />
          </Grid.Col>
        )}
      </Grid>
    </Card>
  );
};

export default OverviewCard;
