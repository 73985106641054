import { createBlock } from '@/lib/parsers/blocks';
import { parseColorScheme, parseLinkField } from '@/lib/parsers/common';
import { parseEntry } from '@/lib/parsers/entries';
import { PageEntry } from '@/lib/parsers/entries/customEntryTypes';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { toStringOrNull } from '@liquorice/utils';

/** Hook to apply card "overrides" and extra features */
export const useExpandingCard = createBlock<
  'blocks_expandingCard_BlockType',
  NoProps,
  PageEntry | null
>((props) => {
  const { entrySingle, heading, content, linkField, colorScheme } = props;

  const item = parseEntry(entrySingle);
  const maybeHeading = toStringOrNull(heading);
  const maybeContent = cleanHtml(content);
  const maybeLink = parseLinkField(linkField);
  const maybeColorScheme = parseColorScheme(colorScheme);

  // Bail early
  if (!item) return null;

  if (maybeHeading) item.entryTitle = maybeHeading;
  if (maybeContent) item.entrySummary = maybeContent;
  if (maybeLink) item.uri = maybeLink.href;
  if (maybeColorScheme) item.colorScheme = maybeColorScheme;

  return item as PageEntry;
});
