import Box, { BoxProps } from '@/components/ui/Box';
import Container, { ContainerProps } from '@/components/ui/Container';
import { BlockMeta } from '@/lib/parsers/blocks';
import { useView } from '@/lib/store/hooks';
import { useForwardedRef } from '@/lib/utils/useForwardedRef';
import { Sprinkles } from '@/theme/sprinkles.css';
import { mergePropsClassName, slugify } from '@liquorice/utils';
import classNames from 'classnames';
import { motion, MotionProps, useInView } from 'framer-motion';
import React from 'react';
import { BlockContainerShim } from './BlockContainer.Shim';
import * as style from './BlockContainer.css';
import { useBlock, useBlocksContextItem } from './useBlock';
import { BlockContainerBtn } from './BlockContainer.Btn';
import Grid, { GridColProps, GridProps } from '../ui/Grid';
import { isEntry } from '@/lib/parsers/entries';

export type BlockContainerProps = BoxProps<
  typeof motion.section,
  style.BlockContainerVariants & {
    children?: React.ReactNode;
    disableContainer?: boolean;
    disableTransition?: boolean;
    ContainerProps?: ContainerProps;
    GridProps?: GridProps;
    GridColProps?: GridColProps;
    maxWidth?: ContainerProps['maxWidth'];
    onInView?: (inView: boolean) => void;
    meta?: BlockMeta;
    customAnchor?: string | null;
    overflow?: boolean;
    paddingY?: Sprinkles['paddingY'] | true;
    marginY?: Sprinkles['marginY'] | true;
  }
>;

export const BlockContainer = React.forwardRef(function BlockContainer(
  {
    children,
    className: classNameProvided,
    disableContainer,
    disableTransition,
    onInView,
    style: customStyleProp,
    marginY,
    paddingY,
    cx,
    maxWidth,
    ContainerProps,
    GridColProps,
    GridProps,
    meta,
    customAnchor,
    overflow,
    ...props
  }: BlockContainerProps,
  forwardedRef?: React.ForwardedRef<HTMLElement>
) {
  const ref = useForwardedRef(forwardedRef);
  const view = useView();
  const withEvent = view?.sectionHandle === 'event';
  const expandable = view?.typeHandle === 'expandable';

  const index = meta?.index;
  const { toggle, isActive } = useBlocksContextItem(index);

  const shim = expandable && !isActive;

  const handleToggle = () => {
    toggle();
  };

  const { anchor } = useBlock();
  const anchorId = customAnchor ? slugify(customAnchor) : anchor;

  const isInView = useInView(ref, {
    once: true,
    margin: '0px 0px -100px 0px',
  });

  const isLast = !!meta?.last;
  const isFirst = !!meta?.first;

  React.useEffect(() => {
    onInView?.(isInView);
  }, [isInView, onInView]);

  const styleProp: MotionProps['style'] = {
    ...(!disableTransition && {
      transition: 'opacity 0.4s ease-in',
      opacity: isInView ? 1 : 0,
    }),
    ...customStyleProp,
  };

  const isReducedWidth = isEntry(view, 'article');

  const customGridColProps: GridColProps = {
    ...(isReducedWidth && {
      offsetMd: 2,
      md: 8,
    }),
    ...GridColProps,
  };

  /**
   * If disableContainer is true, we don't render a container
   * If disableContainer is false, we render a container with the maxWidth provided
   */
  let inner = (
    <Container
      {...{
        ...mergePropsClassName(
          ContainerProps,
          style.container({
            expandable: expandable && !isActive,
            overflow,
          })
        ),
      }}>
      <Grid {...GridProps}>
        <Grid.Col {...customGridColProps}>{children}</Grid.Col>
      </Grid>
      <BlockContainerShim isActive={shim} />
    </Container>
  );

  if (!disableContainer)
    inner = (
      <Container
        {...{
          maxWidth,
          disablePadding: withEvent,
          ...mergePropsClassName(
            ContainerProps,
            style.container({
              expandable: shim,
              overflow,
            })
          ),
        }}>
        <Grid {...GridProps}>
          <Grid.Col {...customGridColProps}>{children}</Grid.Col>
        </Grid>
        <BlockContainerShim isActive={shim} />
      </Container>
    );

  const className = classNames(
    classNameProvided,
    style.root({
      last: isLast,
    })
  );

  let blockMargin = marginY === true ? '3xl' : marginY;
  const blockPadding = paddingY === true ? '5xl' : paddingY;

  if (paddingY === true && (isFirst || isLast)) blockMargin = 'none';

  return (
    <>
      <Box
        as={motion.section}
        className={className}
        data-block={meta?.typename.split('_')[1]}
        id={anchorId}
        ref={ref}
        {...props}
        style={styleProp}
        cx={{
          marginY: blockMargin,
          paddingY: blockPadding,
          ...cx,
        }}>
        {inner}
        <BlockContainerBtn
          {...{
            onClick: handleToggle,
            isActive: expandable,
            maxWidth,
          }}
        />
      </Box>
    </>
  );
});
