import BlockHeader from '@/components/BlockHeader';
import Image from '@/components/Image';
import SkeletonCards from '@/components/SkeletonCards';
import Btn from '@/components/ui/Btn';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import { useCategory } from '@/lib/getters/useCategory';
import { createBlock } from '@/lib/parsers/blocks';
import { Category } from '@/lib/parsers/categories';
import { ImageFragmentTypes, parseImage } from '@/lib/parsers/images';
import { useAppLink } from '@/lib/store/hooks';
import { BlockContainer } from '../..';

export const CategoryTypeBlock = createBlock<'blocks_categoryType_BlockType'>(
  ({ _blockMeta: meta, heading }) => {
    const categoryType = 'genreCategory';
    const category = useCategory({ group: categoryType });
    const loading = category?.loading;

    // FIXME: temporary hack
    const genreCategory = category?.result as unknown as Category<'genreCategory'>[];
    const eventIndexUri = useAppLink('eventIndex')?.uri;

    return (
      <BlockContainer
        {...{
          colorSet: 'primaryTintLighter',
          paper: true,
          meta,
          marginY: 'none',
          paddingY: true,
          disableTransition: true,
          overflow: true,
        }}>
        <BlockHeader
          maxWidth="none"
          heading={heading}
          HeadingProps={{ variant: 'h3', cx: { mB: 'xl' }, autoMargin: false }}
          cx={{ alignItems: 'center' }}
        />
        {loading ? (
          <SkeletonCards
            amount={12}
            xs={4}
            md={2}
            SkeletonItems={{
              bgcolor: 'white',
              items: [
                { variant: 'rounded', height: 115 },
                { variant: 'rounded', height: 25 },
              ],
            }}
          />
        ) : (
          <Grid cx={{ justifyContent: 'center', alignItems: 'end' }}>
            {genreCategory?.map((category, i) => {
              return (
                <Grid.Col
                  key={i}
                  xs={4}
                  md={2}
                  cx={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                  <Btn
                    key={category.id}
                    href={`${eventIndexUri}?${categoryType}=${category.id}`}
                    underline="none"
                    color="primaryTintLighter"
                    variant="text"
                    cx={{ display: 'flex', direction: 'column', alignItems: 'center' }}>
                    <Image
                      alt=""
                      // FIXME: temporary hack
                      {...parseImage(category.imageSingle as unknown as ImageFragmentTypes)}
                    />
                    <Txt
                      color="primary"
                      variant="tiny"
                      uppercase
                      cx={{ mT: '2xs', whiteSpace: 'normal' }}>
                      {category.title}
                    </Txt>
                  </Btn>
                </Grid.Col>
              );
            })}
          </Grid>
        )}
      </BlockContainer>
    );
  }
);

export default CategoryTypeBlock;
