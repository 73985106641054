'use client';

import React from 'react';
import Txt, { TxtProps } from '../ui/Txt';
import { useView } from '@/lib/store/hooks';
import { PageHeaderProps } from '.';
import Grid, { GridColProps } from '../ui/Grid';
import { Entry } from '@/lib/parsers/entries';

export type PageSubTitleProps = TxtProps<
  'span',
  {
    GridColProps?: GridColProps;
    useGridCol?: boolean;
  }
> &
  Partial<PageHeaderProps>;

export default function PageSubTitle({
  children,
  GridColProps,
  useGridCol = true,
  ...props
}: PageSubTitleProps) {
  const page = useView() as Entry<'event'>;
  const summary = page?.entrySubTitle ?? null;

  const content = children || summary;

  const Content = () =>
    content && (
      <Txt as="span" variant="large" {...props}>
        {content}
      </Txt>
    );

  return useGridCol && content ? (
    <Grid.Col md={8} {...GridColProps}>
      <Content />
    </Grid.Col>
  ) : (
    <Content />
  );
}
