import useRecaptchaExecute from '@/components/Formie/Recaptcha/useRecaptchaExecute';
import { FormValues } from '@/components/Formie/formTypes';
import { FormieCaptchas as FormieCaptchasType } from '@/lib/parsers/form/parseFormieCaptchas';
import React from 'react';

type FormieCaptchasProps = {
  captchas: FormieCaptchasType;
};

const useFormieCaptchas = ({ captchas }: FormieCaptchasProps) => {
  const { requestToken, ready } = useRecaptchaExecute();

  const { name, handle } = captchas?.recaptchaCaptcha ?? {};

  const formHasCaptchas = !!handle;

  const getCaptchaValues = React.useCallback(async (): Promise<FormValues> => {
    if (!ready || !handle || !name) return {};

    const token = await requestToken(name);

    const newValue = { name, value: token };
    const values: FormValues = {
      [handle as string]: {
        ...newValue,
        __typename: 'FormieCaptchaType',
      },
    };

    return values;
  }, [handle, name, ready, requestToken]);

  return {
    ready,
    getCaptchaValues,
    formHasCaptchas,
  };
};

export default useFormieCaptchas;
