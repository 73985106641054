'use client';

import Card from '@/components/Card';
import { EntryCardProps } from '@/components/EntryCard/EntryCardProps';
import Box from '@/components/ui/Box';
import Flex from '@/components/ui/Flex';
import { PageEntry } from '@/lib/parsers/entries/customEntryTypes';
import { maybeGet, mergePropsClassName } from '@liquorice/utils';
import { useState } from 'react';
import * as styles from './ExpandingCard.css';
import { useViewColorScheme } from '@/lib/store/hooks';
import { useBreakpointMin } from '@/theme';

export type ExpandingCardProps = EntryCardProps & {
  flipAlignment?: boolean;
};

const ExpandingCard = ({ item, flipAlignment, ...props }: ExpandingCardProps) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const {
    uri: href,
    title,
    entryTitle,
    entrySummary: description,
    entryImage: image,
  } = item as PageEntry;

  const md = useBreakpointMin('md');
  const colorScheme = maybeGet(item, 'colorScheme');
  const { colorScheme: colorSet, oppositeScheme } = useViewColorScheme(colorScheme);

  return (
    <Card
      elevation={false}
      item={{
        title: entryTitle ?? title,
        description,
        image,
        href,
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      {...mergePropsClassName(props, styles.root)}>
      <Box className={styles.imageWrapper}>
        <Card.Image fixedRatio />
      </Box>
      <Flex
        alignItems="start"
        colorSet={colorSet}
        paper={md}
        className={styles.content({ flipAlignment })}>
        <Card.Title monoFont uppercase />
        {(hovered || !md) && <Card.Description autoMargin />}
        <Card.Cta
          color={md ? oppositeScheme : undefined}
          underline="always"
          endIcon="chevronRight"
        />
      </Flex>
    </Card>
  );
};

export default ExpandingCard;
