'use client';

import Box from '@/components/ui/Box';
import Txt from '@/components/ui/Txt';
import UnstyledAccordion from '@/components/UnstyledAccordion';
import { parseFaqFragment } from '@/lib/parsers/common/parseFaq';
import { Entry } from '@/lib/parsers/entries';
import { useView } from '@/lib/store/hooks';
import { FaqCardFragment } from '__generated__/graphql';
import * as styles from './EventLayout.css';

const EventLayoutFaq = () => {
  const page = useView() as Entry<'event'>;
  const { faqMultiple } = page;

  // FIXME: temporary fix
  const faqs = parseFaqFragment(faqMultiple as FaqCardFragment[]);

  return (
    <Box cx={{ mY: '3xl' }}>
      <UnstyledAccordion BoxProps={{ cx: { display: 'flex', rowGap: 'sm', direction: 'column' } }}>
        {faqs.map((faq, index) => {
          return (
            <UnstyledAccordion.Item
              key={index}
              {...{
                index,
                className: styles.accordionItem,
                colorSet: 'primaryTintLighter',
                paper: true,
                HandleProps: {
                  index,
                },
                LabelProps: {
                  children: faq?.heading,
                  color: 'primary',
                  variant: 'text',
                },
                CaretProps: {
                  index,
                },
                BodyProps: {
                  index,
                  children: (
                    <Box cx={{ pX: 'sm' }}>
                      <Txt html>{faq?.content}</Txt>
                    </Box>
                  ),
                },
              }}
            />
          );
        })}
      </UnstyledAccordion>
    </Box>
  );
};

export default EventLayoutFaq;
