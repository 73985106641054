import Carousel from '@/components/Carousel';
import ImageWithCaption from '@/components/ImageWithCaption';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../..';
import { parseImage } from '@/lib/parsers/images';
import { toStringOrNull } from '@liquorice/utils';

const ImageCarouselBlock = createBlock<'blocks_imageCarousel_BlockType'>(
  ({ children = [], _blockMeta: meta }) => {
    return (
      <BlockContainer
        {...{
          meta,
          marginY: true,
          disableTransition: true,
          overflow: true,
        }}>
        <Carousel
          spaceBetween={24}
          // FIXME: Temporary hack
          items={children?.map((v: any, i: number) => (
            <ImageWithCaption
              key={i}
              imageProps={{
                ...parseImage(v.imageSingle),
                ratio: 'landscape',
                rounded: false,
              }}
              caption={toStringOrNull(v.caption)}
            />
          ))}
        />
      </BlockContainer>
    );
  }
);

export default ImageCarouselBlock;
