import SimpleGrid from '@/components/SimpleGrid';
import { createBlock } from '@/lib/parsers/blocks';
import { BlockContainer } from '../../BlockContainer';
import ExpandingCardBlock from '../ExpandingCardBlock';

export const ExpandingCardsBlock = createBlock<'blocks_expandingCards_BlockType'>(
  ({ children = [], _blockMeta: meta }) => {
    return (
      <BlockContainer {...{ meta, marginY: true, disableTransition: true }}>
        <SimpleGrid rowGutter={false} cx={{ rowGap: '3xl' }}>
          {/*
          // FIXME: Temporary hack
          */}
          {children.map((item: any, i: number) => (
            <ExpandingCardBlock key={i} {...item} />
          ))}
        </SimpleGrid>
      </BlockContainer>
    );
  }
);

export default ExpandingCardsBlock;
